import { requestPost } from '@/utils/axios.js'
export const orderList = (params) => {
  return requestPost('/trade-center/v1/trade/order/list', params)
}
export const tradeOrder = (params) => {
  return requestPost('/trade-center/v1/trade/order', params)
}
export const tradeCancelOrder = (params) => {
  return requestPost('/trade-center/v1/trade/cancel-order', params)
}
export const orderDetail = (params) => {
  return requestPost('/trade-center/v1/trade/order/detail', params)
}
export const positionList = (params) => {
  const config = {
    loading: false
  }
  return requestPost('/trade-center/v1/trade/position/list', params, config)
}
