var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"positions-wrap"},[(_vm.requestedStatus === 'requested'&&_vm.orderIdList.length)?_c('div',_vm._l((_vm.orderIdList),function(item,index){return _c('div',{key:index,staticClass:"coin-futures-card margin-top20 padding-bottom20"},[_c('div',{staticClass:"card-title ub ub-h-c"},[_c('div',{staticClass:"main-text-1 font-weight500 font14 margin-right8"},[_vm._v(_vm._s(_vm.futuresObj[item].symbol))]),_c('div',{staticClass:"main-text-3 font12"},[_vm._v(_vm._s(_vm.$t('features.perpetual')))])]),_c('div',{staticClass:"futures-tag ub ub-h-c margin-top8"},[(_vm.futuresObj[item].side === 'BUY')?_c('div',{staticClass:"tag-box font12 margin-right8 padding4 up-color up-bg-color"},[_vm._v(_vm._s(_vm.futuresObj[item].side))]):_vm._e(),(_vm.futuresObj[item].side === 'SELL')?_c('div',{staticClass:"tag-box font12 margin-right8 padding4 sell-bg-color sell-color"},[_vm._v(_vm._s(_vm.futuresObj[item].side))]):_vm._e(),_c('div',{staticClass:"tag-box font12 margin-right8 padding4 main-text-1 background-color-2"},[_vm._v(" "+_vm._s(Number(_vm.futuresObj[item].price_match.split('_')[1]).toFixed(2))+" X")])]),_c('div',{staticClass:"ub ub-btw margin-top8"},[_c('div',[_c('p',{staticClass:"main-text-3 font12"},[_vm._v(_vm._s(_vm.$t('wallets.size'))+"/"+_vm._s(_vm.$t('wallets.amount')))]),_c('p',{staticClass:"font16 font-weight500"},[_vm._v(_vm._s(_vm.futuresObj[item].quantity)+" "),(_vm.futuresObj[item].symbol)?_c('span',[_vm._v(_vm._s(_vm.futuresObj[item].symbol.split('U')[0]))]):_vm._e()]),_c('p',{staticClass:"font12"},[_vm._v(_vm._s(_vm.futuresObj[item].amount)+" USDT")])]),_c('div',{staticClass:"text-r"},[_c('p',{staticClass:"main-text-3 font12"},[_vm._v(_vm._s(_vm.$t('wallets.pnl'))+"/"+_vm._s(_vm.$t('wallets.roe'))+" %")]),_c('p',{staticClass:"font16 font-weight500",class:{
            'up-color': _vm.futuresObj[item]?.profit_amount > 0,
            'sell-color': _vm.futuresObj[item]?.profit_amount < 0
          }},[_vm._v(_vm._s(_vm.futuresObj[item]?.profit_amount))]),_c('p',{staticClass:"font12",class:{
            'up-color': _vm.futuresObj[item]?.profit_ratio > 0,
            'sell-color': _vm.futuresObj[item]?.profit_ratio < 0
          }},[_vm._v(_vm._s(_vm.futuresObj[item]?.profit_ratio)+"%")])])]),_c('div',{staticClass:"margin-top12"},[_c('van-row',[_c('van-col',{attrs:{"span":"10"}},[_c('div',[_c('p',{staticClass:"main-text-3 font12"},[_vm._v(_vm._s(_vm.$t('wallets.emptyPrice')))]),_c('p',{staticClass:"main-text-1 font-weight500"},[_vm._v(_vm._s(_vm.futuresObj[item]?.price))])])]),_c('van-col',{attrs:{"span":"7"}},[_c('div',[_c('p',{staticClass:"main-text-3 font12"},[_vm._v(_vm._s(_vm.$t('wallets.marketPrice')))]),_c('p',{staticClass:"main-text-1 font-weight500"},[_vm._v(_vm._s(_vm.futuresObj[item]?.tag_price))])])]),_c('van-col',{attrs:{"span":"7"}},[_c('div',{staticClass:"text-r"},[_c('p',{staticClass:"main-text-3 font12"},[_vm._v(_vm._s(_vm.$t('wallets.liqPrice'))+" ")]),_c('p',{staticClass:"main-text-1 font-weight500"},[_vm._v(_vm._s(_vm.futuresObj[item]?.close_price))])])])],1)],1),_c('div',{staticClass:"handler-box ub ub-btw margin-top20"},[_c('div',{staticClass:"padding8 background-color-3",on:{"click":function($event){return _vm.closeTradeOrder(_vm.futuresObj[item])}}},[_vm._v(_vm._s(_vm.$t('wallets.marketClose')))])])])}),0):_vm._e(),(_vm.requestedStatus === 'requested'&& !_vm.orderIdList.length)?_c('NoData',{staticStyle:{"margin-top":"40px"},attrs:{"refreshBtn":_vm.refreshBtnFlag,"tip":_vm.$t('market.noDataText')}}):_vm._e(),_c('van-dialog',{attrs:{"title":"Notice","showConfirmButton":false},model:{value:(_vm.dialogFlag),callback:function ($$v) {_vm.dialogFlag=$$v},expression:"dialogFlag"}},[_c('div',{staticClass:"padding16"},[_c('p',{staticClass:"text-c main-text-2"},[_vm._v(" Are you sure to close your entire position with market order？ ")]),_c('div',{staticClass:"ub ub-btw text-c footer-box"},[_c('div',{staticClass:"cancel-box",on:{"click":_vm.closeDialog}},[_vm._v(" Cancel ")]),_c('div',{staticClass:"confirm-box",on:{"click":_vm.confirmFn}},[_vm._v(" Confirm ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }