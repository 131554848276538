<template>
  <!-- wallets/资金 -->
  <div class="wallets-wrap">
    <div class="top-box">
      <div class="card-box">
        <div class="card-header ub ub-btw font-weight400">
          <div class="ub left-box ">
            <div class="font12 margin-right8">
              {{ $t('wallets.totalBalance') }}
            </div>
            <div class="font10 margin-right8 select-unit text-c ub ub-cen">
              <p>USDT</p>
              <div class="ub ub-cen img-box">
                <img src="@/assets/images/walltes/arrow-down-f.svg" alt="" srcset="">
              </div>
            </div>
            <div class="ub ub-cen" @click="clickEyeFn">
              <img  src="@/assets/images/walltes/eye.svg" alt="" srcset="">
            </div>
          </div>
          <div class="ub ub-cen">
            <img @click="goHistory" src="@/assets/images/walltes/list.svg" alt="" srcset="">
          </div>
        </div>
        <div class="amount-box">
          <p class="font32 font-weight700">{{ eyeStatus === 'open' ? assets.amount : '*****'}}</p>
          <p class="main-text-3 font12">≈ ${{ eyeStatus === 'open' ? assets.amount : '*****'}}</p>
        </div>
      </div>
      <div class="ub ub-btw handler-box margin-top16 font14">
        <div class="ub ub-cen button-box padding10" @click="goDeposit">
          <img class="margin-right8" src="@/assets/images/walltes/deposit.svg" alt="" srcset="">
          {{ $t('deposit.deposit') }}
        </div>
        <div class="ub ub-cen button-box padding10" @click="goWithdraw">
          <img class="margin-right8" src="@/assets/images/walltes/withdraw.svg" alt="" srcset="">
          {{ $t('withdraw.withdraw') }}
        </div>
      </div>
    </div>
    <div class="wallets-tab-wrap margin-top20 padding16">
      <!-- <Position></Position> -->
      <van-tabs v-model="active">
        <van-tab v-for="(item,index) in futuresTab" :key="index" :title="$t(item.title)">
          <components :is="item.component"></components>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
import Position from '@/components/walltes/position/index.vue'
// import TotalAssets from '@/components/walltes/totalAssets/index.vue'
import { fundBalance } from '@/api/assetsCenter.js'

import Vue from 'vue'
import { Tab, Tabs } from 'vant'
Vue.use(Tab).use(Tabs)

export default {
  components: {
    Position
    // TotalAssets
  //   Funding, Futures, Overview, Spot
  },
  data () {
    return {
      active: 0,
      assets: {},
      eyeStatus: 'open',
      futuresTab: [
        {
          title: 'wallets.position',
          component: 'Position'
        }
      //   {
      //     title: 'wallets.totalAssets',
      //     component: 'TotalAssets'
      //   }
      ],
      tabList: [
        {
          title: 'wallets.overview',
          component: 'Overview'
        },
        {
          title: 'wallets.spot',
          component: 'Spot'
        },
        {
          title: 'wallets.funding',
          component: 'Funding'
        },
        {
          title: 'wallets.futures',
          component: 'Futures'
        }
      ]
    }
  },
  computed: {
  },
  mounted () {
    this.fundBalanceFn()
  },
  methods: {
    clickEyeFn () {
      const statusObj = {
        open: 'close',
        close: 'open'
      }
      this.eyeStatus = statusObj[this.eyeStatus]
    },
    goHistory () {
      this.$router.push({
        path: '/history/walltes/deposit'
      })
    },
    fundBalanceFn () {
      fundBalance().then(res => {
        console.log(res, 'res')
        this.assets = res.data
        this.$store.dispatch('updateTotalAssets', this.assets)
        this.$toast.clear()
      })
    },
    goDeposit () {
      this.$router.push({
        path: '/depositInfo',
        query: {
          coinName: 'TetherUS',
          label: 'USDT'
        }
      })
    },
    goWithdraw () {
      this.$router.push({
        path: '/networkWithdraw',
        query: {
          coinName: 'TetherUS',
          label: 'USDT'
        }
      })
    }
  }
}
</script>

<style lang="less">
.wallets-wrap{
  box-sizing: border-box;
  background-color: #fff;
  min-height: 100vh;
  padding-bottom: 160px;
  .top-box{
    padding: 30px;
    // background-size: cover;
    background-position: 0px -80px;
    background-image: url('../../assets/images/walltes/bg.svg');
  }
  .card-box{
    .left-box{
      justify-content: flex-start;
      align-items: center;
      .select-unit{
        width: 124px;
        height: 36px;
        flex-shrink: 0;
        border-radius: 4px;
        background: var(--unnamed, #F5F5F5);
        .img-box{
          width: 32px;
          height: 32px;
          margin-left: 4px;
        }
      }
    }
  }
  .handler-box{
    .button-box{
      box-sizing: border-box;
      width: 47%;
      border-radius: 8px;
      background: #F2F0F4;
    }
  }
  .wallets-tab-wrap{
    .van-tab{
      flex:none;
      margin-right: 48px;
    }
    .van-tabs__line{
      background-color: var(--line-gradient-start-color);
  }
  }
}
</style>
