import { requestPost } from '@/utils/axios.js'
export const fundBalance = (params) => {
  return requestPost('/asset-center/v1/fund/balance', params)
}
export const fundWithdraw = (params) => {
  return requestPost('/asset-center/v1/fund/withdraw', params)
}
export const fundBillList = (params) => {
  return requestPost('/asset-center/v1/fund/bill/list', params)
}
export const fundDeposit = (params) => {
  return requestPost('/asset-center/v1/fund/deposit', params)
}
export const fundNetworkList = (params) => {
  return requestPost('/asset-center/v1/fund/network/list', params)
}
