<template>
  <div class="positions-wrap">
    <div  v-if="requestedStatus === 'requested'&&orderIdList.length">
      <div class="coin-futures-card margin-top20 padding-bottom20" v-for="(item,index) in orderIdList" :key="index">
        <div class="card-title ub ub-h-c">
          <div class="main-text-1 font-weight500 font14 margin-right8">{{futuresObj[item].symbol}}</div>
          <div class="main-text-3 font12">{{ $t('features.perpetual') }}</div>
        </div>
        <div class="futures-tag ub ub-h-c margin-top8">
          <div class="tag-box font12 margin-right8 padding4 up-color up-bg-color" v-if="futuresObj[item].side === 'BUY'">{{futuresObj[item].side}}</div>
          <div class="tag-box font12 margin-right8 padding4 sell-bg-color sell-color" v-if="futuresObj[item].side === 'SELL'">{{futuresObj[item].side}}</div>
          <!-- <div class="tag-box font12 margin-right8 padding4 main-text-1 background-color-2">{{$t('wallets.isolated')}}</div> -->
          <div class="tag-box font12 margin-right8 padding4 main-text-1 background-color-2"> {{Number(futuresObj[item].price_match.split('_')[1]).toFixed(2)}} X</div>
        </div>
        <div class="ub ub-btw margin-top8">
          <div>
            <p class="main-text-3 font12">{{ $t('wallets.size') }}/{{ $t('wallets.amount') }}</p>
            <p class="font16 font-weight500">{{futuresObj[item].quantity}} <span v-if="futuresObj[item].symbol">{{ futuresObj[item].symbol.split('U')[0] }}</span></p>
            <p class="font12">{{futuresObj[item].amount}} USDT</p>
          </div>
          <div class="text-r">
            <p class="main-text-3 font12">{{ $t('wallets.pnl') }}/{{ $t('wallets.roe') }} %</p>
            <p class="font16 font-weight500" :class="{
              'up-color': futuresObj[item]?.profit_amount > 0,
              'sell-color': futuresObj[item]?.profit_amount < 0
            }">{{futuresObj[item]?.profit_amount}}</p>
            <p class="font12" :class="{
              'up-color': futuresObj[item]?.profit_ratio > 0,
              'sell-color': futuresObj[item]?.profit_ratio < 0
            }">{{futuresObj[item]?.profit_ratio}}%</p>
          </div>
        </div>
        <div class="margin-top12">
          <van-row>
            <van-col span="10">
              <div>
                <p class="main-text-3 font12">{{$t('wallets.emptyPrice')}}</p>
                <p class="main-text-1 font-weight500">{{futuresObj[item]?.price}}</p>
              </div>
            </van-col>
            <van-col span="7">
              <div>
                <p class="main-text-3 font12">{{$t('wallets.marketPrice')}}</p>
                <!-- <p class="main-text-1 font-weight500">{{markPriceObj[futuresObj[item]?.symbol]?.price}}</p> -->
                <p class="main-text-1 font-weight500">{{futuresObj[item]?.tag_price}}</p>
              </div>
            </van-col>
            <van-col span="7">
              <div class="text-r">
                <p class="main-text-3 font12">{{$t('wallets.liqPrice')}} </p>
                <p class="main-text-1 font-weight500">{{futuresObj[item]?.close_price}}</p>
              </div>
            </van-col>
          </van-row>
        </div>
        <div class="handler-box ub ub-btw margin-top20">
          <div class="padding8 background-color-3" @click="closeTradeOrder(futuresObj[item])">{{$t('wallets.marketClose')}}</div>
        </div>
      </div>
    </div>
    <NoData style="margin-top: 40px;" :refreshBtn="refreshBtnFlag" :tip="$t('market.noDataText')" v-if="requestedStatus === 'requested'&& !orderIdList.length"> </NoData>
    <van-dialog
      v-model="dialogFlag"
      title="Notice"
      :showConfirmButton="false"
    >
      <div class="padding16">
        <p class="text-c main-text-2">
          Are you sure to close your entire position with market order？
        </p>
        <div class="ub ub-btw text-c footer-box">
          <div class="cancel-box" @click="closeDialog">
            Cancel
          </div>
          <div class="confirm-box" @click="confirmFn">
            Confirm
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import NoData from '@/components/market/noData'
import { tradeOrder, positionList } from '@/api/trade.js'
import Vue from 'vue'
import { Row, Col } from 'vant'
Vue.use(Row).use(Col)
export default {
  name: 'Positions',
  components: { NoData },
  computed: { },
  data () {
    return {
      futuresList: [],
      futuresObj: {},
      futuresInfo: [],
      orderIdList: [],
      positionListLoop: null,
      markPriceObj: {},
      dialogFlag: false,
      targetOrder: {},
      requestedStatus: 'pending',
      pageStatus: 'mounted',
      refreshBtnFlag: false
    }
  },
  mounted () {
    this.positionListFn()
  },
  beforeDestroy () {
    console.log('beforeDestroy---------------------')
    clearTimeout(this.positionListLoop)
    this.pageStatus = 'beforeDestroy'
    this.positionListLoop = null
  },
  methods: {
    positionListLoopFn () {
      if (this.positionListLoop) {
        clearTimeout(this.positionListLoop)
        this.positionListLoop = null
      }
      this.positionListLoop = setTimeout(() => {
        this.positionListFn()
      }, 1000)
    },
    positionListFn () {
      positionList({}).then(res => {
        this.requestedStatus = 'requested'
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.futuresObj[res.data[i].id] = res.data[i]
          }
          this.futuresList = res.data
          this.orderIdList = this.futuresList.map(item => {
            return item.id
          })
        }
      })
      if (this.pageStatus !== 'beforeDestroy') {
        this.positionListLoopFn()
      }
    },
    showDialog () {
      this.dialogFlag = true
    },
    closeDialog () {
      this.dialogFlag = false
    },
    confirmFn () {
      this.closeDialog()
      this.tradeOrderFn(this.targetOrder)
    },
    closeTradeOrder (item) {
      this.targetOrder = item
      this.showDialog()
    },
    tradeOrderFn (item) {
      const posSide = item.pos_side
      const price = item.price
      const priceMatch = item.price_match
      const quantity = item.quantity
      const side = item.side === 'BUY' ? 'SELL' : 'BUY'
      const symbol = item.symbol
      const type = item.type
      tradeOrder({
        pos_side: posSide,
        price: price,
        price_match: priceMatch,
        quantity: quantity,
        side: side,
        symbol: symbol,
        type: type
      }).then(res => {
        this.$toast.clear()
        console.log(res, '-----res tradeOrderFn')
      })
    }
  }
}
</script>
<style lang="less">
.positions-wrap{
  .coin-futures-card{
    border-bottom: 1px solid #ECEAEF;
    .handler-box{
      div{
        border-radius: 8px;
        background: #F2F0F4;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
      }
    }
  }
  .sell-color{
    color: #F5465C;
  }
  .sell-bg-color{
    border-radius: 2px;
    background: rgba(245, 70, 92, 0.12);
  }
  .futures-tag{
    .tag-box{
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
  .up-color{
    color: #2DBD85;
  }
  .up-bg-color{
    border-radius: 2px;
    background-color: rgba(45, 189, 133, 0.12);
  }
  .footer-box{
    margin-top: 48px;
  }
  .cancel-box{
    width: 45%;
    border-radius: 8px;
    padding: 16px 0;
    background: #F2F0F4;
  }
  .confirm-box{
    width: 45%;
    border-radius: 8px;
    padding: 16px 0;
    color: #FFF;
    background: #5B21CB;
  }
}
</style>
