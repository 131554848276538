<template>
  <!-- wallets/资金 -->
  <div>
    <div v-if="token">
      <wallets></wallets>
    </div>
  </div>
</template>
<script>
import wallets from '@/components/walltes/index.vue'
import { getToken } from '@/utils/token.js'

export default {
  name: 'Wallets',
  components: {
    wallets
  },
  computed: {
    token () {
      return getToken()
    }
  },
  mounted () {
    if (!this.token) {
      this.$router.push({
        path: '/register/login'
      })
    }
  },
  methods: {
  }
}
</script>
